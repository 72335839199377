import {getCookie, GetQueryString} from './tools';

let saParam = location.origin === 'https://h5.chaincredit.org' ? 'production' : 'default'
var server_url = `https://sensors.juanhand.com/sa?project=${saParam}`

;(function (para) {
  var p = para.sdk_url, n = para.name, w = window, d = document, s = 'script',x = null,y = null;
  w['sensorsDataAnalytic201505'] = n;
  w[n] = w[n] || function(a) {return function() {(w[n]._q = w[n]._q || []).push([a, arguments]);}};
  var ifs = ['track','quick','register','registerPage','registerOnce','clearAllRegister','trackSignup', 'trackAbtest', 'setProfile','setOnceProfile','appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify','login','logout','trackLink','clearAllRegister'];
  for (var i = 0; i < ifs.length; i++) {
    w[n][ifs[i]] = w[n].call(null, ifs[i]);
  }
  if (!w[n]._t) {
    (x) = d.createElement(s), (y) = d.getElementsByTagName(s)[0];
    (x).async = 1;
    (x).src = p;
    w[n].para = para;
    (y).parentNode.insertBefore((x), y);
  }
})({
  sdk_url:`${location.origin}/sensorsdata.min.js`,
  name: 'Sensorsdata',
  app_js_bridge:true,
  use_app_track: true,
  server_url: server_url,
  is_track_single_page: true,
  show_log: false
});

let regParam = {
  h5_channel: getCookie('source') || GetQueryString('source'),
  user_agent: window.navigator.userAgent,
  product_h5: 'chaincredit',
  url: window.location.href,
  is_real_splice: 1
};
// 标识产品线
(window).Sensorsdata.registerPage(regParam);

(window).Sensorsdata.quick('autoTrack');

// h5埋点方法
let SAAPP = {
  saClick(pageName, name, id, params = {}) {
    let trackerParams = {
      tgt_type: 'button',
      page: pageName,
      tgt_name: `h5-chaincredit-${pageName}-${name}`,
      tgt_event_id: 'h5_chaincredit_' + id,
      url: location.href
    }
    Object.assign(trackerParams, params);
    (window).Sensorsdata.track('h5_clk', trackerParams);
  },
  saInput(pageName, name, id, params = {}) {
    let trackerParams = {
      page: pageName,
      tgt_name: `h5-chaincredit-${pageName}-${name}`,
      tgt_event_id: 'h5_chaincredit_' + id,
      url: location.href
    }
    Object.assign(trackerParams, params);
    (window).Sensorsdata.track('h5_input', trackerParams);
  },
  // 神策埋点元素曝光
  saElement(pageName, name, id, params = {}) {
    let trackerParams = {
      tgt_type: 'button',
      page: pageName,
      tgt_name: `h5-chaincredit-${pageName}-${name}`,
      tgt_event_id: 'h5_chaincredit_' + id,
      url: location.href
    }
    Object.assign(trackerParams, params);
    (window).Sensorsdata.track('h5_element_imp', trackerParams);
  },
  // 神策埋点元素曝光
  saAuth(pageName, name, id, err = '', code, params = {}) {
    let trackerParams = {
      page: pageName,
      auth_tgt_name: `h5-chaincredit-${pageName}-${name}`,
      auth_tgt: 'h5_chaincredit_' + id,
      err_msg: err,
      ret_code: code,
      url: location.href
    }
    Object.assign(trackerParams, params);
    (window).Sensorsdata.track('h5_auth', trackerParams);
  }
}
// 导出
export default SAAPP