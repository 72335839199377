import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: '/auth',
    component: () => import('@/view/auth/index') 
  },
]
export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router