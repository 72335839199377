import axios from 'axios'
import configData from './config'
import {setCookie, getCookie, removeCookie} from '../hooks/tools'

const service = axios.create(configData)

const appPath = process.env.VUE_APP_PATH === '/' ? '' : process.env.VUE_APP_PATH

// 状态码错误信息
const codeMessage = {
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  403: '请重新登录',
  404: 'sorry，您的请求不存在',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// Request interceptors
service.interceptors.request.use((config) => {
    if (getCookie('token')) {
      config.headers['Authorization'] = 'Bearer ' + getCookie('token');
    }
    // Indicator.open();
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // Indicator.close()
    const res = response.data
    let token = response.headers['Authorization']
    if (token) {
      setCookie('token', token)
    }
    if (res.code === 403 && response.config.url !== '/limit/query') { 
      // 首页接口不限制，点击跳转到登录
      removeCookie('botToken')
      location.replace(`${appPath}/login`)
    } /*else if  (res.code === 500) {
      // 接口code500
      location.href = '/error?flag=error'
    }*/ else {
      return response.data
    }
  },
  (error) => {
    // Indicator.close()
    // 请求配置发生的错误
    if (!error.response) {

      // 判断是否超时，超时主动中断请求
      let reg = RegExp(/timeout/)
      if (error.message.match(reg)) {
        location.href = appPath + '/error?flag=error'
      }
      return console.log('Error', error.message);
      
    } else {
      // 响应时状态码处理 
      const status = error.response.status;
      // const errortext = codeMessage[status] || error.response.statusText;
    
      /* if (status === 403) {
        removeCookie('botToken')
        location.replace(`${appPath}/login`)
      } else if (status === 404) {
        location.href= appPath+ '/error?flag=404'
      } else if (status <= 504 && status >= 500) {
        location.href = appPath + '/error?flag=error'
      } */

      console.log(error.response)
      return { code: status, message: error.response }; 
    }
  }
)

export default service
