<template>
  <div class="auth-page">
    <div class="no-connet" v-if="!web3Account">
      <h1>Blockchain Wallets</h1>
      <img src="../assets/images/connect.png"/>
      <div class="sec">Connect a blockchain wallet and complete authentication. Please follow the tips on the page.</div>
      <div class="btn" @click="handleWalletConnect">Connect Your Wallet</div>
    </div>
    <div class="connect" v-if="web3Account && !personalSignAuth">
      <img src="../assets/images/connectSuccess.png" class="con-success"/>
      <h2>Connected</h2>
      <span @click="disconnectWalletConnect">Disconnect</span>
      <p>Address</p>
      <h1>{{filterAddress(web3Account)}}</h1>
      <div class="btn" @click="personalSign">To Sign</div>
    </div>
    <div class="connect sign" v-if="personalSignAuth">
      <img src="../assets/images/signSuccess.png" class="con-success"/>
      <h2>Signed</h2>
      <p>Address</p>
      <h1>{{filterAddress(web3Account)}}</h1>
      <div class="btn">Back to Certification Center</div>
    </div>
  </div>
</template>
<script>
import Web3 from "web3";
import Web3Modal, { getInjectedProvider, getInjectedProviderName } from "web3modal";
import { providerOptions } from '@/web3/config';
import { getChainData } from '@/web3/tools';
import { wcLogin, wcMessage, wcIdentify } from '../service/api/auth'
import { setCookie } from '../hooks/tools'
import  Sensor from '../hooks/initSensor'

let web3, web3Provider, web3Account, web3Chain, web3Modal;
export default {
  data() {
    return{
      web3,
      web3Provider,
      web3Account,
      web3Chain,
      personalSignAuth: false,
      connected: false,
      tenantId: '',
      tenantUserId: '',
      signMsg: `message: Welcome to ChainCredit!

                This request will not trigger a blockchain transaction or cost any gas fees.

                Nonce:
                927edeb8-5f7c-42ee-a036-751d89c427a3
                signature: 0x7d98a64f621b4c857462ed582bc3aae1a2bdc38a4a7d4d85a988226a5b5ef4c101b2e40dcf035ac7a20d9394980d06841511f7fc9abe05dc9c699d90a9541ac51c`
    }
  },
  created: async function() {
    Sensor.saElement('认证页' ,'进入认证页', 'enterChaincredit')
    web3Modal = new Web3Modal({
      theme: 'dark',
      network: getChainData(1).network,
      cacheProvider: true,
      providerOptions,
    });
  },
  mounted() {
    // this.login()
  },
  methods: {
    async login () {
      let params = {
        tenantToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1aWQiOiIxIiwiZXhwIjoxNjg0MjE4MTg5LCJ0aWQiOjF9.Od8Yb_7tRiZBkhZKCS1BI_9FEZpMsaW8V9OcKEV7EUZr9xpaMGMifNGQUgqygp4tO2PXiKgQCMQVTlGM-LYMvHshIGkMt1xHvGOFeHoAJzVIymtObKM6ZlVdbN_Bt-nT8y1-npurOiJ5lC5Y5gUd4ZeuBYr3KFFnXuDNbiesyOxqdM1i_46toYRp8RUU4bE3tsyqUetQZJJKS4k4-aUEaiWObmTQIdKstU6PwziWvwam2u9Clmxn33Qt_PrHJtyuHLRuy_zf3EUb5wPh522EEVFu4ZbYMmacN2sc67NybkYGW5rC39XEm1nivTTonq7ZVQb6UN_AZfATEB195iIL-A' // this.$route.query.tenantToken
      }
      let res = await wcLogin(params)
      if (res && res.token) {
        this.tenantUserId = res.tenantUserId
        this.tenantId = res.tenantId
        setCookie('token', res.token)
        // this.configWc()
      }
    },
    async getMessage() {
      let param = {
        address: this.web3Account
      }
      let res = await wcMessage(param)
      if (res && res.message) {
        this.signMsg = res.message
      } else {
        this.$toast(res.message)
      }
    },
    async handleWalletConnect() {
      this.web3Provider = await web3Modal.connect();
      console.log(this.web3Provider)
      this.web3Provider.on('connect', async (chainId) => {
          this.web3Account = (await this.web3.eth.getAccounts())[0];
          this.connected = true
          this.web3Chain = chainId;
          console.log(this.web3Account)
      });
      this.web3Provider.on('accountsChanged', async (accounts) => {
        this.web3Account = accounts[0];
        // this.$emit('Web3AccountChange');
      });

      this.web3Provider.on('chainChanged', async (chainId) => {
        this.web3Account = (await this.web3.eth.getAccounts())[0];
        this.web3Chain = chainId;
      });

      this.web3Provider.on('disconnect', async () => {
        this.web3Account = undefined;
        this.web3Chain = undefined;
      });

      this.web3 = new Web3(this.web3Provider);
      this.web3Account = (await this.web3.eth.getAccounts())[0];
      this.web3Chain = await this.web3.eth.getChainId();
      console.log(this.web3)
    },
    async personalSign() {
      // await this.getMessage()
      let res = await this.web3.eth.personal.sign(this.signMsg, this.web3Account, "")
      if (res) {
        this.personalSignAuth = true
        console.log('签名成功')
        // this.identify(res)
      }
    },
    async identify(result) {
      let param = {
        address: this.web3Account,
        message: this.signMsg,
        signature: result
      }
      let res = await wcIdentify(param)
      if (res && res.address) {
        this.personalSignAuth = true
        Sensor.saElement('认证页' ,'签名成功', 'identifySuccess', res.address, this.tenantUserId, this.tenantId)
      } else {
        this.$toast(res.message)
      }
    },
    async disconnectWalletConnect() {
      if(this.web3Provider && typeof this.web3Provider.close === "function"){
          await this.web3Provider.close()
      }
      web3Modal.clearCachedProvider()
      this.web3.eth.clearSubscriptions()
      this.web3Account  = undefined
      this.web3Chain = undefined
      this.web3Provider = undefined
      this.web3 = undefined
    },
    cacheWeb3Provider() {
      web3Modal.setCachedProvider();
    },
    clearWeb3Provider() {
      web3Modal.clearCachedProvider();
    },
    getInjectedWeb3Provider() {
      return getInjectedProvider();
    },
    getInjectedWeb3ProviderName() {
      return getInjectedProviderName();
    },
    filterAddress(val) {
      if (val) {
        return val.substring(0, 8) + '********' + val.substring(val.length - 8)
      }
      return ''
    }
  }
}
</script>
<style lang="scss">
.auth-page{
  padding: 30px 25px;
  background: #F9F9F9;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  img{
    width: 100px;
    height: 100px;
    display: block;
    margin: 25px auto;
    &.con-success{
      margin: 30px auto 0;
    }
  }
  h1{
    font-size: 22px;
    color: #100F1E; 
    text-align: center;
    font-weight: bold;
  }
  .sec{
    font-size: 15px;
    color: #505A6B;
    text-align: justify;
  }
  .btn{
    background: #4FBEA8;
    border-radius: 6px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 54px;
    margin-top: 28px;
    font-weight: bold;
  }
  .connect{
    text-align: center;
    h2{
      font-size: 16px;
      font-weight: bold;
      color: #505A6B;
      line-height: 30px;
    }
    p{
      font-size: 15px;
      color: #505A6B;
      margin-top: 50px;
      margin-bottom: 15px;
    }
    h1{
      font-size: 18px;
      color: #100F1E;
      font-weight: bold;
    }
    span{
      display: block;
      border: 0.5px solid #4FBEA8;
      border-radius: 6px;
      width: 114px;
      line-height: 30px;
      font-size: 14px;
      color: #4FBEA8;
      margin: 13px auto 0px;
    }
  }
}
</style>
