import Cookies from 'js-cookie';

export const getCookie = (tokenKey) => Cookies.get(tokenKey)
// token默认存储3小时
export const setCookie = (tokenKey, token) => Cookies.set(tokenKey, token,{expires: 3 * (60 * 60 * 1000)})
export const removeCookie = (tokenKey) => Cookies.remove(tokenKey, { path: '' })

export const is_weixn = () => {  
  var ua = navigator.userAgent.toLowerCase();
  const str = ua.match(/MicroMessenger/i);
  if (str && (str.toString().toLowerCase() === 'micromessenger')) {  
      return true;  
  } else {  
      return false;  
  }  
}
/**
 * 获取地址栏的参数
 */
export function GetQueryString (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURI(r[2])
  }
  return ''
}