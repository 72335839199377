import { createApp } from 'vue';
import router from './router/index'
import App from './App.vue';
import './registerServiceWorker';
import '@/assets/style/reset.scss'

/* router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    let title = to.meta.title
    document.title = title
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
  window.scroll(0,0);
})
 */

const app = createApp(App);

app.use(router).mount('#app');

