import request from '../http'
import Qs from 'qs'

// 登录
export const wcLogin = (param) => request({
  method: 'POST',
  url: '/user/tenant-login',
  data: Qs.stringify(param)
})

// 获取配置
export const wcConfig = () => request({
  method: 'GET',
  url: '/wallet-connect/configs'
})

//获取消息串
export const wcMessage = (param) => request({
  method: 'POST',
  url: '/wallet-connect/message',
  data: Qs.stringify(param)
})

// 认证
export const wcIdentify = (param) => request({
  method: 'POST',
  url: '/user/identify-ethereum-wallet-addresses',
  data: Qs.stringify(param)
})


